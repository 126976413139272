import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const TermsOfUse = () => {
  const navigate = useNavigate();

  const navgiateToHomepage = () => {
    navigate("/");
  };

  return (
    <div className="main-card main-card-content">
      <div className="formGrid grid">
        <div className="field mt-5 col-6 col-offset-3">
          <Button
            label="zurück zur Homepage"
            severity="secondary"
            onClick={navgiateToHomepage}
          />
          <h2>Nutzungsbedingungen</h2>
          <h3>1. Allgemeines</h3>
          <p>
            1.1 Diese Nutzungsbedingungen regeln die Nutzung dieser Website
            durch die registrierten Zahnärzte und Abrechnungskräfte („Nutzer“).
            Durch die Registrierung und Nutzung der Website erklären Sie sich
            mit diesen Bedingungen einverstanden.
            <br /> <br />
            1.2 Die ACTA-Soft behält sich das Recht vor, diese
            Nutzungsbedingungen jederzeit zu ändern. Änderungen werden den
            Nutzern per E-Mail mitgeteilt oder auf der Website veröffentlicht.
          </p>
          <h3>2. Nutzung der Website </h3>
          <p>
            2.1 Die Website ermöglicht es Zahnärzten und Abrechnungskräften,
            Behandlungsprotokolle hochzuladen und eine automatisierte Empfehlung
            zu den abzurechnenden Leistungen zu erhalten.
            <br /> <br />
            2.2 Die Nutzung der Website ist ausschließlich für registrierte
            Zahnärzte und Abrechnungskräfte zulässig. Die Registrierung
            erfordert die Angabe wahrheitsgemäßer und vollständiger
            Informationen.
            <br /> <br />
            2.3 Der Nutzer ist verpflichtet, seine Zugangsdaten sicher
            aufzubewahren und diese nicht an Dritte weiterzugeben. Der Nutzer
            ist für alle Aktivitäten verantwortlich, die unter Verwendung seiner
            Zugangsdaten erfolgen.
          </p>
          <h3>3. Haftung und Gewährleistung </h3>
          <p>
            3.1 Die Empfehlungen, die durch die Website generiert werden,
            basieren auf den von den Nutzern eingegebenen Daten und sind
            ausschließlich als unverbindliche Orientierungshilfe zu verstehen.
            <br /> <br />
            3.2 ACTA-Soft übernimmt keine Haftung für die Korrektheit,
            Vollständigkeit oder Aktualität der bereitgestellten Empfehlungen.
            Die Nutzung der Empfehlungen erfolgt auf eigenes Risiko des Nutzers.
            <br /> <br />
            3.3 Die Website ersetzt keine fachliche Beratung oder Prüfung durch
            den Zahnarzt oder dessen Abrechnungskräfte. Der Nutzer ist allein
            verantwortlich für die Abrechnung der erbrachten Leistungen und die
            Einhaltung der geltenden gesetzlichen Vorschriften.
            <br /> <br />
            3.4 ACTA-Soft haftet nicht für direkte oder indirekte Schäden, die
            aus der Nutzung der Website oder den generierten Empfehlungen
            resultieren.
          </p>
          <h3>4. Datenschutz </h3>
          <p>
            4.1 Die Verarbeitung personenbezogener Daten erfolgt in
            Übereinstimmung mit der <a href="/privacy">Datenschutzerklärung </a>
            der Website.
            <br /> <br />
            4.2 Der Nutzer erklärt sich damit einverstanden, dass seine Daten
            zum Zwecke der Bereitstellung der Dienste der Website gespeichert
            und verarbeitet werden.
          </p>
          <h3>5. Urheberrecht </h3>
          <p>
            5.1 Alle Inhalte der Website, einschließlich Texte, Grafiken, Logos
            und Software, sind urheberrechtlich geschützt und dürfen ohne
            ausdrückliche schriftliche Genehmigung von ACTA-Soft nicht
            vervielfältigt, verbreitet oder anderweitig genutzt werden.
          </p>
          <h3>6. Beendigung der Nutzung </h3>
          <p>
            6.1 Die ACTA-Soft behält sich das Recht vor, die Registrierung eines
            Nutzers zu sperren oder zu löschen, wenn der Nutzer gegen diese
            Nutzungsbedingungen verstößt.
            <br /> <br />
            6.2 Der Nutzer kann seine Registrierung jederzeit ohne Angabe von
            Gründen kündigen.
          </p>

          <h3>7. Schlussbestimmungen </h3>
          <p>
            7.1 Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein
            oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen
            unberührt.
            <br /> <br />
            7.2 Diese Nutzungsbedingungen unterliegen dem Recht der
            Bundesrepublik Deutschland. Gerichtsstand für alle Streitigkeiten
            aus oder im Zusammenhang mit diesen Nutzungsbedingungen ist München.
          </p>
          <hr />
          <h2>Haftungsausschluss</h2>
          <p>
            Die auf dieser Website bereitgestellten Informationen und
            Empfehlungen dienen ausschließlich der unverbindlichen Unterstützung
            von Zahnärzten und Abrechnungskräften bei der Abrechnung ihrer
            Leistungen. Sie ersetzen keinesfalls eine individuelle Beratung,
            Prüfung oder fachliche Einschätzung durch den Zahnarzt oder die
            Abrechnungskraft selbst.
            <br /> <br />
            ACTA-Soft übernimmt keine Gewährleistung oder Haftung für die
            Richtigkeit, Vollständigkeit oder Aktualität der bereitgestellten
            Empfehlungen. Jegliche Nutzung der bereitgestellten Informationen
            erfolgt auf eigenes Risiko. Der Nutzer ist allein verantwortlich für
            die ordnungsgemäße Abrechnung seiner Leistungen und die Einhaltung
            der geltenden gesetzlichen Vorschriften.
            <br /> <br />
            ACTA-Soft haftet nicht für direkte oder indirekte Schäden, die aus
            der Nutzung der Website oder der bereitgestellten Informationen
            resultieren.
          </p>
          <Button
            label="zurück zur Homepage"
            severity="secondary"
            onClick={navgiateToHomepage}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;

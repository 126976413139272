import { Button } from "primereact/button";
import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { authenticate } from "../services/authenticate";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";

const LoginModal = ({ visible, setVisible, setSignupVisible }) => {
  const toast = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loginErr, setLoginErr] = useState("");

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === "" && password === "") {
        setEmailErr("Email ist erforderlich");
        setPasswordErr("Password ist erforderlich");
        resolve({
          email: "Email ist erforderlich",
          password: "Password ist erforderlich",
        });
      } else if (email === "") {
        setEmailErr("Email ist erforderlich");
        resolve({ email: "Email ist erforderlich", password: "" });
      } else if (password === "") {
        setPasswordErr("Password ist erforderlich");
        resolve({ email: "", password: "Password ist erforderlich" });
      } else if (password.length < 6) {
        setPasswordErr("muss 8 Zeichen haben");
        resolve({ email: "", password: "muss 8 Zeichen haben" });
      } else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const handleClick = () => {
    setEmailErr("");
    setPasswordErr("");
    validation()
      .then(
        (res) => {
          if (res.email === "" && res.password === "") {
            authenticate(email, password)
              .then(
                (data) => {
                  setLoginErr("");
                  showSuccess();
                  // Navigate("/dashboard");
                  setVisible(false);
                },
                (err) => {
                  console.log(err);
                  setLoginErr(err.message);
                }
              )
              .catch((err) => console.log(err));
          }
        },
        (err) => {
          console.log(err);
          showError(err);
        }
      )
      .catch((err) => {
        console.log(err);
        showError(err);
      });
  };

  const signup = () => {
    setVisible(false);
    setSignupVisible(true);
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Login erfolgreich",
    });
  };

  const showError = (err) => {
    toast.current.show({
      severity: "error",
      summary: "Login fehlgeschlagen",
      detail: err.message,
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Login"
        visible={visible}
        style={{ width: "600px" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="field grid">
          <div className="field col-12 grid">
            <label htmlFor="Email" className="col-4">
              Email
            </label>
            <div className="col-8">
              <InputText
                id="Email"
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                invalid={emailErr}
                style={{ width: "100%" }}
              />
            </div>
            {emailErr && (
              <div className="col-8 col-offset-4">
                <Message severity="error" text={emailErr} />
              </div>
            )}
          </div>
          <div className="field col-12 grid">
            <label htmlFor="Password" className="col-4">
              Passwort
            </label>
            <div className="col-8">
              <Password
                id="Password"
                value={password}
                onChange={(e) => {
                  formInputChange("password", e.target.value);
                }}
                invalid={passwordErr}
                feedback={false}
                style={{ width: "100%" }}
                inputStyle={{ width: "100%" }}
              />
            </div>
            {passwordErr && (
              <div className="col-8 col-offset-4">
                <Message severity="error" text={passwordErr} />
              </div>
            )}
          </div>
          {loginErr && (
            <div className="field col-12 grid">
              <Message severity="error" text={loginErr} />
            </div>
          )}

          <div className="field col-6">
            <Button
              severity="secondary"
              onClick={signup}
              label="Kostenlos Registrieren"
            />
          </div>
          <div className="field col-4 col-offset-2 flex justify-content-end flex-wrap">
            <Button
              type="submit"
              onClick={handleClick}
              icon="pi pi-sign-out"
              label="Log in"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LoginModal;

import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import userpool from "../userpool";
import { logout } from "../services/authenticate";
import { Toast } from "primereact/toast";

const headerStyle = {
  padding: "10px 20px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  backgroundColor: "#f7f7f7",
};

const logoStyle = {
  height: "50px",
  marginRight: "15px",
};

const titleStyle = {
  fontSize: "40px",
  fontWeight: "bold",
  color: "#000",
};

const Header = () => {
  const toast = useRef(null);

  const [loginVisible, setLoginVisible] = useState(false);
  const [signupVisible, setSignupVisible] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    let user = userpool.getCurrentUser();
    console.log(user);
    setUser(user);
  }, [loginVisible]);

  const handleLogout = () => {
    logout();
    confirmLogout();
    setUser();
  };

  const confirmLogout = () => {
    toast.current.show({
      severity: "success",
      summary: "Logout erfolgreich",
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <header style={headerStyle}>
          <div className="flex justify-content-between flex-wrap">
            <div className="flex algin-items-start">
              <img src="/logo.png" alt="Logo" style={logoStyle} />
              <div style={titleStyle}>DentiWise</div>
            </div>
            <div className="flex align-items-center justify-content-center ">
              {!user && (
                <Button
                  label="Kostenlos Anmelden"
                  icon="pi pi-sign-in"
                  onClick={() => setLoginVisible(true)}
                />
              )}
              {user && (
                <Button
                  label="Log out"
                  icon="pi pi-sign-out"
                  onClick={handleLogout}
                />
              )}
            </div>
          </div>
        </header>
      </div>
      <LoginModal
        visible={loginVisible}
        setVisible={setLoginVisible}
        setSignupVisible={setSignupVisible}
      />
      <SignupModal visible={signupVisible} setVisible={setSignupVisible} />
    </div>
  );
};

export default Header;

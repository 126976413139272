import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import React, { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

import RequestForm from "./components/RequestForm";
import DevForm from "./components/DevForm";
import Imprint from "./components/Imprint";
import DataPrivacyTerms from "./components/DataPrivacyTerms";
import TermsOfUse from "./components/TermsOfUse";
import userpool from "./userpool";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App() {
  useEffect(() => {
    let user = userpool.getCurrentUser();
    if (user) {
      <Navigate to="/dashboard" replace />;
    }
  }, []);

  return (
    <div>
      <div style={{ minHeight: "100vh" }} className="layout-main-container">
        <div className="layout-main flex flex-column">
          <Header />
          <div className="flex-grow-1">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<RequestForm />} />
                <Route path="/dev" element={<DevForm />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/privacy" element={<DataPrivacyTerms />} />
                <Route path="/terms" element={<TermsOfUse />} />
              </Routes>
            </BrowserRouter>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;

import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const DataPrivacyTerms = () => {
  const navigate = useNavigate();

  const navgiateToHomepage = () => {
    navigate("/");
  };

  return (
    <div className="main-card main-card-content">
      <div className="formGrid grid">
        <div className="field mt-5 col-6 col-offset-3">
          <Button
            label="zurück zur Homepage"
            severity="secondary"
            onClick={navgiateToHomepage}
          />
          <h2>Datenschutzerklärung</h2>
          <h3>1. Allgemeine Hinweise und Pflichtinformationen</h3>
          <p>
            <strong>Datenschutz</strong>
            <br />
            Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
            Wir behandeln Ihre personenbezogenen Daten vertraulich und
            entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerklärung.
            <br />
            <br />
            <strong>Verantwortliche Stelle</strong>
            <br />
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
            <br /> <br />
            ACTA-Soft UG (haftungsbeschränkt) <br />
            Josef-Scheidl-Straße 2c, 85221 Dachau <br />
            +49 (0)8131 3792947 <br />
            info@acta-soft.com <br />
            <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten entscheidet.
          </p>
          <h3>2. Datenerfassung auf unserer Website</h3>
          <p>
            <strong>Server-Log-Dateien</strong>
            <br />
            Der Provider der Website erhebt und speichert automatisch
            Informationen in sogenannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
            <br />
            <br />
            Browsertyp und Browserversion Verwendetes Betriebssystem Referrer
            URL Hostname des zugreifenden Rechners Uhrzeit der Serveranfrage
            IP-Adresse
            <br />
            <br />
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage
            von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und
            der Optimierung seiner Website – hierzu müssen die
            Server-Log-Dateien erfasst werden.
            <br />
            <br />
            <strong>Cookies</strong>
            <br />
            Unsere Website verwendet Cookies. Cookies sind kleine Textdateien,
            die auf Ihrem Endgerät gespeichert werden und die Ihr Browser
            speichert. Sie dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen.
            <br />
            <br />
            Einige der von uns verwendeten Cookies sind sogenannte
            „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.
            <br />
            <br />
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
            <br />
            <br />
            Die Speicherung von Cookies erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der Speicherung von Cookies zur technisch fehlerfreien und
            optimierten Bereitstellung seiner Dienste.
          </p>
          <h3>3. Datenverarbeitung bei der Nutzung der Website</h3>
          <p>
            <strong>Registrierung auf dieser Website</strong>
            <br />
            Zur Nutzung bestimmter Funktionen unserer Website, wie z.B. das
            Hochladen von Behandlungsprotokollen, müssen Sie sich als Zahnarzt
            registrieren. Die bei der Registrierung abgefragten Pflichtangaben
            müssen vollständig angegeben werden. Andernfalls werden wir die
            Registrierung ablehnen.
            <br />
            <br />
            Die Verarbeitung der bei der Registrierung eingegebenen Daten
            erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO). Sie können eine bereits erteilte Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt
            vom Widerruf unberührt.
            <br />
            <br />
            Die bei der Registrierung erfassten Daten werden von uns
            gespeichert, solange Sie auf unserer Website registriert sind und
            werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen
            bleiben unberührt.
            <br />
            <br />
            <strong>Verarbeitung von Behandlungsdaten</strong>
            <br />
            Die auf der Website hochgeladenen Behandlungsprotokolle und die
            daraus generierten Abrechnungsempfehlungen werden von uns
            verarbeitet und gespeichert, um die Funktionalität der Website
            bereitzustellen. Diese Datenverarbeitung erfolgt auf Grundlage von
            Art. 6 Abs. 1 lit. b DSGVO, da sie zur Erfüllung eines Vertrages
            oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.
          </p>
          <h3>4. Weitergabe von Daten</h3>
          <p>
            <strong>Weitergabe an Dritte</strong>
            <br />
            Wir geben Ihre personenbezogenen Daten nur dann an Dritte weiter,
            wenn dies im Rahmen der Vertragsabwicklung notwendig ist,
            beispielsweise an Zahlungsdienstleister oder IT-Dienstleister. Eine
            darüber hinausgehende Weitergabe Ihrer Daten erfolgt nur, wenn Sie
            ausdrücklich eingewilligt haben oder wenn wir gesetzlich dazu
            verpflichtet sind.
          </p>
          <h3>5. Rechte des Nutzers</h3>
          <p>
            <strong>Auskunft, Berichtigung, Löschung und Sperrung</strong>
            <br />
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung sowie ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit an uns wenden.
            <br />
            <br />
            <strong>Widerrufsrecht</strong>
            <br />
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
            <br />
            <br />
            <strong>
              Beschwerderecht bei der zuständigen Aufsichtsbehörde
            </strong>
            <br />
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <h3>6. Änderungen dieser Datenschutzerklärung</h3>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung gelegentlich
            anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen
            entspricht oder um Änderungen unserer Leistungen in der
            Datenschutzerklärung umzusetzen. Für Ihren erneuten Besuch gilt dann
            die neue Datenschutzerklärung.
          </p>
          <Button
            label="zurück zur Homepage"
            severity="secondary"
            onClick={navgiateToHomepage}
          />
        </div>
      </div>
    </div>
  );
};

export default DataPrivacyTerms;

import { Button } from "primereact/button";
import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";

const FeedbackModal = ({ visible, setVisible, saveFeedback }) => {
  const toast = useRef(null);

  const [notes, setNotes] = useState("");

  const submitFeedback = () => {
    saveFeedback("negative", notes);
    setNotes("");
    setVisible(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Ihre Rückmeldung ist uns wichtig!"
        visible={visible}
        style={{ width: "500px" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="field grid">
          <div className="field col-12 grid">
            <label htmlFor="Notes" className="col-12">
              Geben Sie hier Ihre Anmerkungen, Vorschläge oder Kritik zu der
              Antwort ein.
            </label>
            <div className="col-12">
              <InputTextarea
                id="NOTES"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                style={{ width: "100%", height: "calc(1.5em * 3)" }}
                autoResize={false} // Disable auto resize to maintain height
              />
            </div>
            <p>Wir schätzen Ihr Feedback sehr!</p>
          </div>
          <div className="field col-4 col-offset-4">
            <Button
              type="submit"
              onClick={submitFeedback}
              icon="pi pi-send"
              label="Absenden"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FeedbackModal;

import { Button } from "primereact/button";
import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import userpool from "../userpool";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Checkbox } from "primereact/checkbox";

const SignupModal = ({ visible, setVisible }) => {
  const toast = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [agbAccepted, setAgbAccepted] = useState(false);

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === "" && password === "") {
        setEmailErr("Email ist erforderlich");
        setPasswordErr("Password ist erforderlich");
        resolve({
          email: "Email ist erforderlich",
          password: "Password ist erforderlich",
        });
      } else if (email === "") {
        setEmailErr("Email ist erforderlich");
        resolve({ email: "Email ist erforderlich", password: "" });
      } else if (password === "") {
        setPasswordErr("Password ist erforderlich");
        resolve({ email: "", password: "Password ist erforderlich" });
      } else if (password.length < 6) {
        setPasswordErr("muss 8 Zeichen haben");
        resolve({ email: "", password: "muss 8 Zeichen haben" });
      } else {
        resolve({ email: "", password: "" });
      }
      reject("");
    });
  };

  const handleClick = (e) => {
    setEmailErr("");
    setPasswordErr("");
    validation()
      .then(
        (res) => {
          if (res.email === "" && res.password === "") {
            const attributeList = [];
            attributeList.push(
              new CognitoUserAttribute({
                Name: "email",
                Value: email,
              })
            );
            let username = email;
            userpool.signUp(
              username,
              password,
              attributeList,
              null,
              (err, data) => {
                if (err) {
                  console.log(err);
                  showError(err);
                } else {
                  console.log(data);
                  showSuccess();
                  setVisible(false);
                }
              }
            );
          }
        },
        (err) => {
          console.log(err);
          showError(err);
        }
      )
      .catch((err) => {
        console.log(err);
        showError(err);
      });
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Registrierung erfolgreich",
      detail: "Bitte bestätigen Sie nun die Email",
    });
  };

  const showError = (err) => {
    toast.current.show({
      severity: "error",
      summary: "Registrierung fehlgeschlagen",
      detail: err.message,
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Registrierung"
        visible={visible}
        style={{ width: "500px" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="field grid">
          <div className="field col-12 grid">
            <label htmlFor="Email" className="col-4">
              Email
            </label>
            <div className="col-8">
              <InputText
                id="Email"
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                invalid={emailErr}
                style={{ width: "100%" }}
              />
            </div>
            {emailErr && (
              <div className="col-8 col-offset-4">
                <Message severity="error" text={emailErr} />
              </div>
            )}
          </div>
          <div className="field col-12 grid">
            <label htmlFor="Password" className="col-4">
              Passwort
            </label>
            <div className="col-8">
              <Password
                id="Password"
                value={password}
                onChange={(e) => {
                  formInputChange("password", e.target.value);
                }}
                invalid={passwordErr}
                feedback={false}
                style={{ width: "100%" }}
                inputStyle={{ width: "100%" }}
              />
            </div>
            <div className="col-12">
              <Checkbox
                inputId="AGB"
                name="AGB"
                value="AGB"
                onChange={(e) => setAgbAccepted(e.checked)}
                checked={agbAccepted}
              />
              <label htmlFor="AGB" className="ml-2">
                <a
                  href="/terms"
                  style={{ color: "#007ad9", textDecoration: "none" }}
                  target="_blank"
                >
                  Nutzungsbedingungen
                </a>{" "}
                akzeptieren
              </label>
            </div>
            {passwordErr && (
              <div className="col-8 col-offset-4">
                <Message severity="error" text={passwordErr} />
              </div>
            )}
          </div>

          <div className="field col-4 col-offset-8">
            <Button
              type="submit"
              onClick={handleClick}
              label="Registrieren"
              disabled={!agbAccepted}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SignupModal;

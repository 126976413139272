import React from "react";

const footerStyle = {
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  backgroundColor: "#f7f7f7",
};

const Footer = () => {
  return (
    <footer style={footerStyle} className="py-4">
      <div className="flex justify-content-center flex-column">
        <div className="flex align-items-center justify-content-center">
          <div className="mx-2">
            <a
              href="/privacy"
              style={{ color: "#007ad9", textDecoration: "none" }}
            >
              Datenschutzerklärung
            </a>
          </div>
          <div className="mx-2">
            <a
              href="/terms"
              style={{ color: "#007ad9", textDecoration: "none" }}
            >
              Nutzungsbedingungen
            </a>
          </div>
          <div className="mx-2">
            <a
              href="/imprint"
              style={{ color: "#007ad9", textDecoration: "none" }}
            >
              Impressum
            </a>
          </div>
        </div>
        <div className="flex align-items-center justify-content-center">
          <p style={{ color: "#555" }}>
            © {new Date().getFullYear()} ACTA-Soft. Alle Rechte vorbehalten.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
